import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { CTACard, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';

import GiveContentTrans from '../../components/GiveContentTrans';

import ProtoVideo from '../../assets/animeeritudprototyyp.mp4';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Mobiiliäppide UI/UX disain"
          description="Disainime kaasaegseid ja kasutajasõbralike mobiilirakendusi ja mobiilseid veebirakendusi. Kasutusmugavus > Visuaalne disain > Prototüüp."
          image="/pagecover.jpg"
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Mobile app UI/UX design"
          description="We design modern and user-friendly mobile applications and mobile web applications. User experience > Visual design > Prototype"
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Mobiiliäppide disain</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Mobile App Design</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
              Disainime kaasaegseid ja kasutajasõbralikke mobiilirakendusi.
              Omame kogemust nii veebipõhiste, iOs kui ka Android
              mobiiliäppidega.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              We design modern and user-friendly mobile applications. We have
              experience with web-based, iOS, and Android mobile apps.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/mobiilirakendused.jpg"
            alt="Mobiilirakenduse disain"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/mobiilirakendused.jpg"
            alt="Mobile app design"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>Hea mobiiliäpp</h2>
            <p>
              Toome oma projektides ärieesmärgid kokku kasutajate vajadustega.
              Mobiiliapplikatsioonid on muutnud meie elu lihtsamaks.
              Mobiilirakenduste ja mobiilsete veebilehtede kasutatavus aina
              suureneb ja nad muutuvad tähtsamaks kõikide ettevõtete jaoks.
              Selleks, et kliente võita ja luua neile positiivset kogemust, on
              tähtis omada mobiilirakendust, mis oleks hästi disainitud.
            </p>
            <p>
              App Stores on üle 1,5 miljoni rakenduse. Google Plays üle 2
              miljoni. Statistika kohaselt 23% kasutajatest käivitab mobiiliappi
              ainult ühe korra.
            </p>
            <p>
              Üks oluline põhjus, miks inimesed äppe hülgavad on selles, et seda
              ei ole mugav kasutada. Läbimõeldult disainides ja lahendades
              kasutajate probleeme, saab seda vältida.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>A good mobile app</h2>
            <p>
              In our projects, we bring business goals together with user needs.
              Mobile applications have made our lives easier. The usability of
              mobile applications and mobile websites is increasing, and they
              are becoming more important for all businesses. To win customers
              and create a positive experience for them, it is important to have
              a well-designed mobile application.
            </p>
            <p>
              There are over 1.5 million apps on the App Store and over 2
              million on Google Play. Statistics show that 23% of users launch a
              mobile app only once.
            </p>
            <p>
              One important reason why people abandon apps is because they are
              not convenient to use. By designing thoughtfully and solving user
              problems, this can be avoided.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Kasutusmugavus ja visuaalne disain</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Usability and visual design</h2>
        </GiveContentTrans>

        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Parim kasutuskogemus on sageli märkamatu. Kasutajad muutuvad üha
                proffessionaalsemaks ja omandavad uusimaid võtteid, kuidas äppe
                kiiremini ja mugavamalt kasutada.
              </p>
              <p>
                Meil on kogemus disainida mobiiliäppe alates iPhone esimesest
                versioonist ja luua operatsioonisüsteemi prototüüpi Huaweile.
                Gives ei ole meil ühtset protsessi, mis sobib kõikidele
                rakendustele. Leiame lahendused ja kohendame oma protsessid nii,
                et saaksime luua parimat tulemust mõistliku kuluga.
              </p>
              <p>
                Kui tegemist on keerukama rakendusega, siis alustame
                prototüüpimisega juba alguses. Simuleerime rakenduse
                kasutuskogemust läbi lihtsakoeliste raamistikkujundusvaadete (nö
                wireframe). Wireframe kasutamise eelis on kiirem loomisprotsess.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                The best user experience is often unnoticed. Users are becoming
                more professional and acquiring the latest techniques for using
                apps faster and more conveniently.
              </p>
              <p>
                We have experience designing mobile apps since the first version
                of the iPhone and creating operating system prototypes for
                Huawei. At Give, we do not have a uniform process that fits all
                applications. We find solutions and adjust our processes to
                create the best results at a reasonable cost.
              </p>
              <p>
                When it comes to a more complex application, we start
                prototyping from the beginning. We simulate the user experience
                of the application through simple wireframes. The advantage of
                using wireframes is a faster creation process.
              </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <StaticImage
              src="../../assets/mobiili-wireframed.jpg"
              alt="Mobiiliappi kasutusmugavus"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="content-row has-media">
          <div className="media content-col-half">
            <StaticImage
              src="../../assets/mobiili-kujundus.jpg"
              alt="Mobiiliappi kujundus"
              layout="fullWidth"
            />
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Visuaalne disain toetab kasutusmugavust. Kehva kasutajakogemust
                ei suuda ka ilusaim disain positiivseks muuta. Halb disain
                suudab aga rikkuda kogu äpi.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                Visual design supports usability. Even the most beautiful design
                cannot make a poor user experience positive. However, bad design
                can ruin the entire app.
              </p>
            </GiveContentTrans>
          </div>
        </div>
      </section>

      <section className="description-section inner-grid">
        <div className="content-row page-copy">
          <GiveContentTrans language="et">
            <h2>Mobiilirakenduste prototüüpimine</h2>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>Mobile app prototyping</h2>
          </GiveContentTrans>

          <div className="img-100 video-container">
            <video width="100%" height="auto" autoPlay loop muted poster="">
              <source src={ProtoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Gives teeme nii klikitavaid kui ainult animeeritud prototüüpe.
                Klikitavad prototüübid on ideaalsed kasutajatestide
                läbiviimisel.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                We create both clickable and only animated prototypes at Gives.
                Clickable prototypes are ideal for conducting user testing.
              </p>
            </GiveContentTrans>
          </div>

          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Animeeritud prototüüpe kasutame oma ideede visualiseerimiseks ja
                arendajatele sisendiks. Hästi disainitud animatsioon parandab
                kasutusmugavust ja muudab rakenduse kasutamise kaasahaaravamaks.
                Animatsioon aitab kasutajal mõista liikumist erinevate vaadete
                vahel ja annab talle tagasisidet tegevustest.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                We use animated prototypes to visualize our ideas and provide
                input to developers. Well-designed animation improves usability
                and makes using the application more engaging. Animation helps
                the user understand movement between different views and
                provides feedback on actions.
              </p>
            </GiveContentTrans>
          </div>
        </div>
      </section>
    </main>
  
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;